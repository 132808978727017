import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { RouterProps as Props } from "./Router.types";
import ErrorBoundry from "../ErrorBoundry/ErrorBoundry";
import CONSTANTS from "config/constants";

const Router: React.FC<Props> = props => {
  const { NO_AUTH_PATH, ENTRY_PATH } = CONSTANTS;

  const Entry = lazy(() => import("pages/Entry/Entry.page"));
  const Home = lazy(() => import("pages/Home/Home.page"));
  const Login = lazy(() => import("pages/Login/Login.page"));
  const FourZeroFour = lazy(() => import("pages/404/404.page"));
  const FiverZeroZero = lazy(() => import("pages/500/500.page"));
  const ForgotPassword = lazy(() =>
    import("pages/ForgotPassword/ForgotPassword.page")
  );
  const ResetPassword = lazy(() =>
    import("pages/ResetPassword/ResetPassword.page")
  );

  return (
    <ConnectedRouter history={props.history}>
      <ErrorBoundry>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={"/404"} component={() => <FourZeroFour />} />
            <Route exact path={"/500"} component={() => <FiverZeroZero />} />
            <Route exact path={NO_AUTH_PATH} component={() => <Login />} />
            <Route
              exact
              path={"/forgot-password"}
              component={() => <ForgotPassword />}
            />
            <Route
              exact
              path={"/reset-password"}
              component={() => <ResetPassword />}
            />
            <Route
              path={ENTRY_PATH}
              component={() => <Entry component={() => <Home />} />}
            />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </ErrorBoundry>
    </ConnectedRouter>
  );
};

Router.defaultProps = {};

export default Router;
