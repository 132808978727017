import { TicketBox, Ticket } from "types/ticket.types";

// Utilities for the ticket entity

export const findTicketBoxById = (boxes: TicketBox[], id: string) => {
  return boxes.find(box => {
    return box.id === id;
  });
};

export const findTicketBoxByName = (boxes: TicketBox[], name: string) => {
  return boxes.find(box => {
    return box.name.toLowerCase() === name.toLowerCase();
  });
};

export const sortByWeight = (a: TicketBox, b: TicketBox) => {
  if (a.weigth > b.weigth) {
    return 1;
  } else if (a.weigth < b.weigth) {
    return -1;
  } else {
    throw new Error("Ticket Boxes weight should never be the same");
  }
};

export const countUnreadTickets = (tickets: Ticket[] | undefined): number => {
  if (!tickets) {
    return 0;
  }
  return tickets.reduce((acc, next) => {
    if (!next.read) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const findTicket = (
  ticketId: string,
  boxId: string,
  boxes: TicketBox[]
): Ticket | undefined => {
  const box = boxes.find(box => box.id === boxId);
  if (box) {
    return box.tickets?.find(ticket => ticket.id === ticketId);
  }
  return undefined;
};
