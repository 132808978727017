import { Component } from "react";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends Component<any, any> {
  componentDidCatch(error: any, errorInfo: any) {
    // TODO: logErrorToMyService(error, errorInfo);
    this.props.history.push("/500");
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
