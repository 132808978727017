// Ticket entity types and interfaces

export interface Ticket {
  id: string;
  ticketId: string;
  category: string;
  subcategory: string;
  read: boolean;
  responsible?: Responsible;
  author: Agent;
  status: TICKET_STATUS;
  flowStatus: TICKET_FLOW_STATUS;
  statusUpdatedAt: string;
  scores?: Scores;
  entries: Entry[];
  createdAt: string;
}

export interface Scores {
  treatment: number;
  service: number;
}

export interface Agent {
  id: string;
  name: string;
  lastName: string;
  avatar?: string;
}

export interface Responsible {
  id: string;
  area: string;
  agent: Agent;
}

export enum TICKET_STATUS {
  PENDING = "Pendiente",
  ATTENDING = "En atención",
  IN_PROCESS = "En proceso",
  RESOLVED = "Resuelto",
  CLOSED = "Cerrado"
}

export enum TICKET_FLOW_STATUS {
  OPENED = "Abierto",
  RESOLVED = "Resuelto",
  CLOSED = "Cerrado"
}

export interface Entry {
  id: string;
  read: boolean;
  createdAt: string;
  area: string;
  note: Note;
  attachments: Attachment[];
  activities: Activity[];
  author: Agent;
}

export interface Attachment {
  id: string;
  name: string;
  uri: string;
  extension: Extensions;
}

export enum ImageExtensions {
  SVG = "svg",
  PNG = "png",
  JPEG = "jpeg",
  JPG = "jpg"
}

export enum FileExtensions {
  PDF = "pdf",
  EXCEL = "xls",
  WORD = "docsx"
}

export type Extensions = typeof ImageExtensions & typeof FileExtensions;

export interface Note {
  id: string;
  private: boolean;
  text: string;
}

export interface Activity {
  id: string;
  title: string;
  color: string;
  [key: string]: string;
}

export interface TicketBox {
  id: string;
  flowStatus?: TICKET_FLOW_STATUS;
  name: string;
  icon?: string;
  tickets: Ticket[] | undefined;
  weigth: number;
}

export interface TicketCategory {
  id: string;
  name: string;
  subcategories: TicketSubcategory[];
}

export interface TicketSubcategory {
  id: string;
  name: string;
}
