// User entity builder functions
import faker from "faker";

import { User, UserRol } from "types/user.types";
import {
  genId,
  genMobilPhone,
  genCompanyName,
  chooseRandomEnum,
  genEmail,
  genBiasBoolean,
  genNumber,
  fillNumber,
  genAvatar
} from "./common.builder";

const genUserName = () => {
  return `${faker.name.firstName()} ${faker.name.firstName()}`;
};

const genUserLastName = () => {
  return `${faker.name.lastName()} ${faker.name.lastName()}`;
};

const getRol = () => {
  return chooseRandomEnum(UserRol);
};

const genStartDate = () => {
  return faker.date.recent(100).toISOString();
};

const genUserPhone = () => {
  const should = genBiasBoolean(0.8);
  if (!should) {
    return;
  }
  return genMobilPhone("593");
};

const genUserPhoneExtension = () => {
  const should = genBiasBoolean(0.2);
  if (!should) {
    return;
  }
  return fillNumber(String(genNumber(300)));
};

export const buildUser = (overrides: Partial<User> = {}): User => {
  const withAvatar = genBiasBoolean(0.7);
  return {
    id: genId(),
    name: genUserName(),
    lastName: genUserLastName(),
    email: genEmail(),
    phone: {
      value: genUserPhone(),
      extension: genUserPhoneExtension()
    },
    center: {
      id: genId(),
      name: genCompanyName(),
      address: faker.address.city()
    },
    rol: getRol(),
    avatar: withAvatar ? genAvatar() : undefined,
    startDate: genStartDate(),
    ...overrides
  };
};
