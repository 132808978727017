import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import {
  useSelector as useSelectorRR,
  useDispatch as useDispatchRR
} from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory as createHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk, { ThunkMiddleware } from "redux-thunk";

import reducers from "./reducers";
import { AppState, AppActions, ThunkDispatch } from "./store.types";

// Allow redux dev tools to work for this app
let composeEnhancers: any = compose;
if (process.env.NODE_ENV === "development") {
  // Allow redux dev tools to work for this app
  composeEnhancers = composeWithDevTools({ trace: true });
}

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [
  thunk as ThunkMiddleware<AppState, AppActions>,
  routeMiddleware
];

// combine reducers into a single one
const reducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

// Create the general redux system + apply middleware if needed
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

const useSelector = <T>(selector: (state: AppState) => T) =>
  useSelectorRR<AppState, T>(selector);

const useDispatch = () => useDispatchRR<ThunkDispatch>();

export default store;
export { history, reducer, useSelector, useDispatch };
