import { css } from "styled-components";
import { rgba } from "polished";

const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; //16px default to 10px
  }

  body {
    box-sizing: border-box;
    margin: 0;
  }

  ol,
  ul,
  dl {
    margin-top: initial;
    margin-bottom: initial;
  }

  p {
    margin-bottom: initial;
  }

  .ant-input-affix-wrapper:hover {
    border-color: var(--palette-primary);
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--palette-primary);
    box-shadow: ${props =>
      `0 0 0 2px ${rgba(props.theme.palette.primary, 0.2)}`};
  }

  .ant-input:hover {
    border-color: var(--palette-primary);
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: var(--palette-primary);
    box-shadow: ${props =>
      `0 0 0 2px ${rgba(props.theme.palette.primary, 0.2)}`};
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--palette-primary);
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: var(--palette-primary);
    box-shadow: ${props =>
      `0 0 0 2px ${rgba(props.theme.palette.primary, 0.2)}`};
  }

  .ant-checkbox-inner {
    background-color: var(--palette-white);
    border-color: var(--palette-primary);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--palette-primary);
    border-color: var(--palette-primary);
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--palette-primary);
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-checked::after {
    border-color: var(--palette-primary);
  }
`;

export default base;
