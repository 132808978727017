import { css } from "styled-components";

const animations = css`
  @keyframes shine-move {
    0% {
      left: -100%;
    }
    100% {
      left: 75%;
    }
  }

  @keyframes shine-move-2 {
    0% {
      left: -200%;
    }
    100% {
      left: 250%;
    }
  }

  @keyframes shine-move-3 {
    0% {
      left: -200%;
    }
    100% {
      left: 200%;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    750% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default animations;
