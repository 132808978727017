// User entity types and interfaces

export interface User {
  id: string;
  name: string;
  lastName: string;
  email?: string;
  phone: Phone;
  rol: UserRol;
  startDate: string;
  center: Center;
  avatar?: string;
}

export interface Phone {
  value?: string;
  extension?: string;
}

export enum UserRol {
  ADMINISTRATION = "Administración",
  TECHNICIAN = "Técnico",
  AGENT = "Agente"
}

export interface Center {
  id: string;
  name: string;
  address: string;
}
