// Base entities builders
import faker from "faker";
import {
  KnowledgeBaseCategory,
  Author,
  Article,
  Block
} from "types/base.types";
import {
  genParagraph,
  chooseRandom,
  genId,
  genFirstName,
  genLastName,
  genNumber
} from "./common.builder";

import boxSVG from "assets/images/base/box.svg";
import cardSVG from "assets/images/base/card.svg";
import flagSVG from "assets/images/base/flag.svg";
import laptopSVG from "assets/images/base/laptop.svg";
import lightBulbSVG from "assets/images/base/light-bulb.svg";

export const buildAuthor = (overrides: Partial<Author> = {}): Author => {
  return {
    id: genId(),
    fullName: `${genFirstName()} ${genLastName()}`,
    ...overrides
  };
};

export const genAuthors = (quantity?: number): Author[] => {
  const num = quantity ?? Math.floor(Math.random() * 5);
  const authors = [];
  for (let i = 0; i < num; i++) {
    const author = buildAuthor();
    authors.push(author);
  }
  return authors;
};

export const buildNumberArray = (
  quantity?: number,
  max = 200,
  others: string[] = []
): string[] => {
  const num = quantity ?? Math.floor(Math.random() * max);
  const ids = [];
  for (let i = 0; i < num; i++) {
    const id = genNumber({ min: 1000, max: 2000 }).toString();
    if (!others.includes(id)) {
      ids.push(id);
    }
  }
  return ids;
};

export const buildBlock = (weight = 0): Block => {
  const random = Math.random();
  if (random < 0.7) {
    return { type: "text", content: genParagraph(5), weight };
  } else if (random < 0.9) {
    return { type: "image", content: faker.random.image(), weight };
  } else if (random < 0.95) {
    return { type: "video", content: "", weight };
  } else {
    return { type: "document", content: "", weight };
  }
};

export const genDescription = (quantity?: number): Block[] => {
  const num = quantity ?? Math.floor(Math.random() * 10);
  const blocks = [];
  for (let i = 0; i < num; i++) {
    const block = buildBlock(i);
    blocks.push(block);
  }
  return blocks;
};

export const buildArticle = (overrides: Partial<Article> = {}): Article => {
  const dislikes = buildNumberArray(undefined, 50);
  return {
    id: genId(),
    title: faker.commerce.productName(),
    shortDescription: genParagraph(3),
    description: genDescription(),
    authors: [buildAuthor()],
    dislikes,
    likes: buildNumberArray(undefined, 200, dislikes),
    createdAt: faker.date.recent(100).toISOString(),
    updatedAt: faker.date.recent(80).toISOString(),
    ...overrides
  };
};

export const genArticles = (
  quantity?: number,
  authors: Author[] = []
): Article[] => {
  const num = quantity ?? Math.floor(Math.random() * 10);
  const articles = [];
  for (let i = 0; i < num; i++) {
    const author = chooseRandom(authors);
    const article = buildArticle({ authors: [author] });
    articles.push(article);
  }
  return articles;
};

export const buildKnowledgeBaseCategory = (
  overrides: Partial<KnowledgeBaseCategory> = {}
): KnowledgeBaseCategory => {
  const authors = genAuthors();
  return {
    id: genId(),
    title: faker.commerce.department(),
    description: genParagraph(3),
    icon: chooseRandom([boxSVG, cardSVG, flagSVG, laptopSVG, lightBulbSVG]),
    articles: genArticles(undefined, authors),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ...overrides
  };
};

export const genKnowledgeBaseCategories = (
  quantity?: number
): KnowledgeBaseCategory[] => {
  const num = quantity ?? Math.floor(Math.random() * 10);
  const categories = [];
  for (let i = 0; i < num; i++) {
    const category = buildKnowledgeBaseCategory();
    categories.push(category);
  }
  return categories;
};
