// Tickets services
import {
  Ticket,
  TicketBox,
  TICKET_FLOW_STATUS
} from "../../types/ticket.types";
import {
  genTickets,
  buildTicketBox
} from "../../utils/testUtils/builders/tickets.builder";

import fileSVG from "assets/images/inbox/file.svg";
import folderSVG from "assets/images/inbox/folder.svg";
import hourglassSVG from "assets/images/inbox/hourglass.svg";

export const fetchTicketBoxes = (): Promise<TicketBox[]> => {
  return new Promise((resolved, rejected) => {
    resolved([
      buildTicketBox({ id: "0", name: "Abiertos", icon: fileSVG, weigth: 0 }),
      buildTicketBox({
        id: "1",
        name: "Resueltos",
        icon: folderSVG,
        weigth: 1
      }),
      buildTicketBox({
        id: "2",
        name: "Cerrados",
        icon: hourglassSVG,
        weigth: 2
      })
    ]);
  });
};

export const fetchBoxTickets = (ticketBoxId: string): Promise<Ticket[]> => {
  return new Promise((resolved, rejected) => {
    setTimeout(() => {
      if (ticketBoxId === "0") {
        const overrides = { flowStatus: TICKET_FLOW_STATUS.OPENED };
        resolved([
          ...genTickets(undefined, overrides),
          ...genTickets(undefined, overrides)
        ]);
      } else if (ticketBoxId === "1") {
        const overrides = { flowStatus: TICKET_FLOW_STATUS.RESOLVED };
        resolved([...genTickets(undefined, overrides)]);
      } else if (ticketBoxId === "2") {
        const overrides = { flowStatus: TICKET_FLOW_STATUS.CLOSED };
        resolved([
          ...genTickets(undefined, overrides),
          ...genTickets(undefined, overrides),
          ...genTickets(undefined, overrides),
          ...genTickets(undefined, overrides)
        ]);
      }
    }, 2000);
  });
};
