import { AppState, ThunkDispatch } from "redux/store.types";
import { fetchCategories } from "services/base/base.service";

export const SET_CATEGORIES = "SET_CATEGORIES";

export const setCategories = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  try {
    const categories = await fetchCategories();
    return dispatch({ type: SET_CATEGORIES, payload: categories });
  } catch (e) {
    throw new Error(`Fetching categories failed, ${e.message}`);
  }
};
