// Knowledge base services

import { genKnowledgeBaseCategories } from "utils/testUtils/builders/base.builder";
import { KnowledgeBaseCategory } from "types/base.types";

export const fetchCategories = (): Promise<KnowledgeBaseCategory[]> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(genKnowledgeBaseCategories());
    }, 1000);
  });
};
