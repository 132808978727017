// Tickets entity builder
import faker from "faker";

import {
  Ticket,
  TICKET_STATUS,
  TicketBox,
  TICKET_FLOW_STATUS,
  Responsible,
  Entry,
  Note,
  Activity,
  Agent,
  Attachment,
  FileExtensions,
  ImageExtensions,
  Scores
} from "types/ticket.types";
import {
  genId,
  genNumber,
  getBoolean,
  chooseRandomEnum,
  genParagraph,
  genBiasBoolean,
  genWords,
  fillNumber,
  genFirstName,
  genLastName,
  genAvatar,
  genTitle,
  chooseRandom
} from "./common.builder";

const genTicketId = (max: number) => {
  const num = String(genNumber(max));
  return fillNumber(num, max);
};

const genCategoryName = () => {
  return faker.random.word();
};

const genSubcategory = () => {
  return faker.random.words(genNumber({ min: 1, max: 3 }));
};

const getTicketStatus = (): TICKET_STATUS => {
  return chooseRandomEnum(TICKET_STATUS);
};

const genTicketFlowStatus = (): TICKET_FLOW_STATUS => {
  return chooseRandomEnum(TICKET_FLOW_STATUS);
};

const genStatusUpdateDate = () => {
  return faker.date.recent(3).toISOString();
};

const genCommentCreationDate = () => {
  return faker.date.recent(2).toISOString();
};

export const buildNote = (overrides: Partial<Note> = {}): Note => {
  return {
    id: genId(),
    text: genParagraph(),
    private: false,
    ...overrides
  };
};

const buildFormField = () => {
  const filled = genBiasBoolean(0.9);
  const words = genNumber({ min: 1, max: 3 });
  const lines = genNumber({ min: 1, max: 2 });
  const key = faker.random.words(words);
  const value = genParagraph(lines);
  return {
    [key]: filled ? value : ""
  };
};

const genFormFields = (quantity?: number) => {
  const num = quantity ?? Math.floor(Math.random() * 5 + 1);
  let formFields = {};
  for (let i = 0; i < num; i++) {
    formFields = { ...formFields, ...buildFormField() };
  }
  return formFields;
};

export const buildActivity = (overrides: Partial<Activity> = {}): Activity => {
  const colors = ["#F65058", "#F2C94C", "#5F53C9"];
  return {
    id: genId(),
    title: genTitle(),
    color: chooseRandom(colors),
    ...overrides,
    ...genFormFields()
  };
};

export const genActivities = (quantity?: number): Activity[] => {
  const defaultActivity = buildActivity({
    title: "Ticket creado",
    color: "#F2C94C"
  });
  const withoutActivities = genBiasBoolean(0.4);
  if (withoutActivities) {
    return [defaultActivity];
  }
  const num = quantity ?? Math.floor(Math.random() * 5);
  const activities = [defaultActivity];
  for (let i = 0; i < num; i++) {
    activities.push(buildActivity());
  }
  return activities;
};

export const buildAttachement = (): Attachment => {
  const file = genBiasBoolean(0.2);
  const ext = chooseRandomEnum(file ? FileExtensions : ImageExtensions);
  const randomWords = genNumber({ min: 1, max: 4 });
  return {
    id: genId(),
    extension: ext,
    name: `${faker.lorem.slug(randomWords)}.${ext}`,
    uri: faker.image.avatar()
  };
};

export const genAttachments = (quantity?: number): Attachment[] => {
  const withoutAttachments = genBiasBoolean(0.6);
  if (withoutAttachments) {
    return [];
  }
  const num = quantity ?? Math.floor(Math.random() * 5);
  const attachments = [];
  for (let i = 0; i < num; i++) {
    attachments.push(buildAttachement());
  }
  return attachments;
};

export const buildEntry = (overrides: Partial<Entry> = {}): Entry => {
  return {
    id: genId(),
    read: genBiasBoolean(0.3),
    author: buildAgent(),
    area: faker.commerce.department(),
    createdAt: genCommentCreationDate(),
    note: buildNote(),
    activities: genActivities(),
    attachments: genAttachments(),
    ...overrides
  };
};

export const genEntries = (quantity?: number): Entry[] => {
  const num = quantity ?? Math.floor(Math.random() * 9) + 1;
  const entries = [];
  for (let i = 0; i < num; i++) {
    entries.push(buildEntry());
  }
  return entries;
};

const genResponsible = (): Responsible => {
  return {
    id: genId(),
    area: faker.commerce.department(),
    agent: buildAgent()
  };
};

export const buildAgent = (overrides: Partial<Agent> = {}): Agent => {
  const withAvatar = genBiasBoolean(0.7);
  return {
    id: genId(),
    name: genFirstName(),
    lastName: genLastName(),
    avatar: withAvatar ? genAvatar() : undefined,
    ...overrides
  };
};

export const buildScore = (): Scores => {
  return {
    service: genNumber({ min: 1, max: 10 }),
    treatment: genNumber({ min: 1, max: 10 })
  };
};

export const buildTicket = (overrides: Partial<Ticket> = {}): Ticket => {
  const flowStatus = genTicketFlowStatus();
  const isClosed =
    flowStatus === TICKET_FLOW_STATUS.CLOSED ||
    overrides.flowStatus === TICKET_FLOW_STATUS.CLOSED;
  return {
    id: genId(),
    ticketId: genTicketId(300),
    read: getBoolean(),
    author: buildAgent(),
    category: genCategoryName(),
    subcategory: genSubcategory(),
    status: getTicketStatus(),
    responsible: genResponsible(),
    flowStatus,
    statusUpdatedAt: genStatusUpdateDate(),
    entries: genEntries(),
    scores: isClosed ? buildScore() : undefined,
    createdAt: faker.date.recent(300).toISOString(),
    ...overrides
  };
};

export const genTickets = (
  quantity?: number,
  overrides: Partial<Ticket> = {}
): Ticket[] => {
  const num = quantity ?? Math.floor(Math.random() * 10);
  const tickets = [];
  const maxTicketId = 300;
  let currentPointer = 0;
  for (let i = 0; i < num; i++) {
    const loopMax = maxTicketId - num + i;
    const loopMin =
      currentPointer + 1 <= loopMax ? currentPointer + 1 : loopMax;
    currentPointer = genNumber({ min: loopMin, max: loopMax });
    const newTicketId = fillNumber(String(currentPointer), maxTicketId);
    tickets.push(buildTicket({ ticketId: newTicketId, ...overrides }));
  }
  return tickets;
};

export const buildTicketBox = (
  overrides: Partial<TicketBox> = {}
): TicketBox => {
  return {
    id: genId(),
    name: genWords(1),
    tickets: undefined,
    weigth: genNumber(10),
    flowStatus: chooseRandomEnum(TICKET_FLOW_STATUS),
    icon: "",
    ...overrides
  };
};
