import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { Helmet } from "react-helmet";
import { ReactQueryConfigProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import faker from "faker";
import moment from "moment";
import "moment/locale/es";
import "antd/dist/antd.css";

import { AppProps as Props } from "./App.types";
import { ErrorBoundary } from "../../utils/bugsnag";
import CONSTANTS from "../../config/constants";
import i18n from "../../i18n/i18n";
import store, { history } from "../../redux/store";
import Router from "../../components/global/Router/Router";
import ThemeProviderContainer from "containers/ThemeProviderContainer/ThemeProviderContainer";

const App: React.FC<Props> = () => {
  const env = process.env.REACT_APP_ENV as string;
  const includedEnv = CONSTANTS.INCLUDED_LOGGER_ENVS.includes(env);

  useEffect(() => {
    moment.locale("es");
    faker.locale = "es";
  }, []);

  const app = (
    <ReactQueryConfigProvider config={CONSTANTS.REACT_QUERY_CONFIG}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProviderContainer>
            <Helmet>
              <meta
                name="theme-color"
                content={store.getState().Theme.selectedTheme.palette.primary}
              />
              <title>{CONSTANTS.TITLE}</title>
            </Helmet>
            <Router history={history} />
          </ThemeProviderContainer>
        </Provider>
      </I18nextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryConfigProvider>
  );

  return includedEnv ? <ErrorBoundary>{app}</ErrorBoundary> : app;
};

export default App;
