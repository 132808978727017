import { BaseState as State, BaseActions as Actions } from "./base.types";
import { SET_CATEGORIES } from "./base.actions";

const initialState: State = {
  categories: null
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export default reducer;
