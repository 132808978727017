import { css } from "styled-components";

const typography = css`
  body {
    font-family: "SFProDisplay", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem;
  }
  p {
    color: black;
    opacity: 0.65;
  }
`;

export default typography;
