import Auth from "./auth/auth.reducer";
import Theme from "./theme/theme.reducer";
import Tickets from "./tickets/tickets.reducer";
import Base from "./base/base.reducer";

export default {
  Auth,
  Theme,
  Tickets,
  Base
};
